<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Role
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                class="uk-input"
                type="text"
                name="name"
                placeholder="-"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Catatan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="formData.description"
                class="uk-textarea"
                rows="3"
                name="catatan"
                placeholder="-"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <!-- <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">Akses</div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <select
                  class="uk-select"
                  v-model="accessChoose"
                  name="akses"
                  disabled
                >
                  <option value="">Pilih</option>
                  <option value="1">Admin Dashboard dan Aplikasi</option>
                  <option value="2">Admin Dashboard</option>
                  <option value="3">Aplikasi</option>
                </select>
              </div>
            </div>
          </div> -->
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
              style="display:flex"
            >
              <label-status :status="formData.status" />
            </div>
          </div>
        </div>
      </div>
      <div class="uk-margin-top">
        <div class="uk-overflow-auto">
          <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
            <thead class="thead-table-paranje">
              <tr>
                <th class="uk-width-large">
                  <span class="uk-text-bold">Menu</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">View</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Add</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Edit</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Delete</span>
                </th>
              </tr>
            </thead>
            <template>
              <tbody>
                <tr
                  v-for="(v, i) in collectDashboardMenu"
                  :key="i"
                >
                  <td>{{ v.name || '-' }}</td>
                  <td class="uk-text-center">
                    <input
                      class="uk-checkbox"
                      disabled
                      type="checkbox"
                      :checked="v.view"
                      @change="checkRulesDashboard($event, i, `view`)"
                    >
                  </td>
                  <td class="uk-text-center">
                    <input
                      v-show="v.name !== 'Dashboard'"
                      class="uk-checkbox"
                      disabled
                      type="checkbox"
                      :checked="v.add"
                      @change="checkRulesDashboard($event, i, `add`)"
                    >
                  </td>
                  <td class="uk-text-center">
                    <input
                      v-show="v.name !== 'Dashboard'"
                      class="uk-checkbox"
                      disabled
                      type="checkbox"
                      :checked="v.edit"
                      @change="checkRulesDashboard($event, i, `edit`)"
                    >
                  </td>
                  <td class="uk-text-center">
                    <input
                      v-show="v.name !== 'Dashboard'"
                      class="uk-checkbox"
                      disabled
                      type="checkbox"
                      :checked="v.delete"
                      @change="checkRulesDashboard($event, i, `delete`)"
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
      <div class="uk-margin-medium-top">
        <b>Aplikasi</b>
        <div
          style="margin-top: 16px;"
          class="uk-overflow-auto"
        >
          <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
            <thead class="thead-table-paranje">
              <tr>
                <th class="uk-width-small">
                  <span class="uk-text-bold">Menu</span>
                </th>
                <th class="uk-width-small uk-text-center">
                  <span class="uk-text-bold">Akses</span>
                </th>
              </tr>
            </thead>
            <template>
              <tbody>
                <tr
                  v-for="(v, i) in collectMobileMenu"
                  :key="i"
                >
                  <td>{{ v.name || '-' }}</td>
                  <td class="uk-text-center">
                    <input
                      class="uk-checkbox"
                      type="checkbox"
                      disabled
                      :checked="v.access"
                      @change="checkRulesMobile($event, i, `access`)"
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
      <div class="uk-grid uk-grid uk-margin-top">
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </button>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l uk-width-1-2@xl uk-text-right">
          <!-- <button class="uk-button uk-button-danger" type="button" @click="showDeleteConfirmModal">
            <img v-lazy="`${images}/icon/trash-white.svg`" alt="" class="uk-margin-small-right">
            Hapus
          </button> -->
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToEdit"
          >
            <img
              v-lazy="`${images}/icon/edit.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Ubah
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import 'vue2-datepicker/index.css'
import { STATUS } from '@/utils/constant'
import sidebarJson from "@/components/globals/sidebar/sidebar.json"
import sidebarFarmJson from "@/components/globals/sidebar/sidebar-farm.json"
import sidebarUserJson from "@/components/globals/sidebar/sidebar-user.json"
import sidebarMasterJson from "@/components/globals/sidebar/sidebar-master.json"
import sidebarMobileJson from "../mobileAccess.json"
import LabelStatus from '@/components/globals/LabelStatus'

export default {
  components: {
    LabelStatus
  },
  props: {
    dataRole: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      images: PREFIX_IMAGE,
      sidebar: sidebarJson,
      sidebarFarm: sidebarFarmJson,
      sidebarUser: sidebarUserJson,
      sidebarMaster: sidebarMasterJson,
      sidebarMobile: sidebarMobileJson,
      status: STATUS,
      statusSwitch: true,
      formData: {},
      accessChoose: '',
      roleAccess: [],
      collectDashboardMenu: [],
      collectMobileMenu: sidebarMobileJson,
      collectAllMenu: []
    }
  },
  computed: {
    ...mapGetters({
      roles: 'role/roles',
      metaRole: 'role/meta'
    })
  },
  watch: {
    async dataRole() {
      this.formData = {
        id: this.dataRole.id,
        name: this.dataRole.name,
        description: this.dataRole.description,
        status : this.dataRole.status
      }
      await this.compareData()
    }
  },
  async created() {
    await this.buildDataMenu()
  },
  methods: {
    checkRulesDashboard ($event, i, valueRules) {
      const checkedInput = $event.target.checked
      this.collectDashboardMenu[i][valueRules] = checkedInput
    },
    checkRulesMobile ($event, i, valueRules) {
      const checkedInput = $event.target.checked
      this.collectMobileMenu[i][valueRules] = checkedInput
    },
    ...mapMutations({
      setModalEdit: 'role/SET_MODAL_EDIT'
    }),
    async buildDataMenu() {
      const app = this
      const allSidebar = [...app.sidebar, ...app.sidebarFarm, ...app.sidebarMaster, ...app.sidebarUser]
      allSidebar.forEach(element => {
        if (element.childDisplay) {
          for (const child of element.children) {
            const obj = {
              name: child.name,
              view:false,
              add:false,
              edit:false,
              delete:false,
              access:false,
              type: 'dashboard'
            }
            app.collectDashboardMenu.push(obj)
          }
        } else {
          const obj = {
            name: element.name,
            view:false,
            add:false,
            edit:false,
            delete:false,
            access:false,
            type: 'dashboard'
          }
          app.collectDashboardMenu.push(obj)
        }
      })
    },
    async compareData() {
      const app = this
      // DASHBOARD
      app.collectDashboardMenu.forEach((el, i) => {
        const findType = app.dataRole.role_management.find(x => x.name === el.name)
        app.collectDashboardMenu[i].view = findType.view
        app.collectDashboardMenu[i].add = findType.add
        app.collectDashboardMenu[i].edit = findType.edit
        app.collectDashboardMenu[i].delete = findType.delete
      })
      // MOBILE
      app.collectMobileMenu.forEach((el, i) => {
        const findType = app.dataRole.role_management.find(x => x.name === el.name)
        app.collectMobileMenu[i].access = findType.access
      })
    },
    goToBack() {
      this.$router.push(`/admin/role`)
    },
    goToEdit() {
      this.$router.push(`/admin/role/edit/${this.dataRole.id}`)
    }
  }
}
</script>

<style scoped>
.uk-checkbox:checked {
  background-color: #025231 !important;
}
.uk-checkbox:focus {
    outline: none;
    border-color: #025231 !important;
}
.uk-table-striped tbody tr:nth-of-type(odd) {
    border-top: 1px solid #F0F3F5 !important;
    border-bottom: 10px solid #F0F3F5 !important;
}
</style>
